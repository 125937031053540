import { takeLatest, call, put } from "redux-saga/effects";

import {
  fetchItinerarySuccess,
  fetchItineraryFailed,
  fetchItinerary
} from "./itinerary.action";

import itineraryTypes from "./itinerary.types";
// import { fetchItinerary } from "./itinerary.api";

export function* fetchItineraryASync(url) {
  // console.log(url);
  //   console.log(data);
  try {
    const data = yield call(fetchItinerary, url.payload);
    yield put(fetchItinerarySuccess(data));
  } catch (error) {
    yield put(fetchItineraryFailed(error));
  }
}


const itinerarySaga = [takeLatest(itineraryTypes.FETCH_ITINERARY_START, fetchItineraryASync)];

export default itinerarySaga