import changeVa from './updateVa.types'
const INITIAL_STATE = {
  kodeVa: []
}

const updateVaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case changeVa.CHANGE_VA:
      return {
        ...state,
        kodeVa: action.payload
      }
    default:
      return state
  }
}

export default updateVaReducer
