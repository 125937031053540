import { takeEvery, call, put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { paymentFetch, paymentSuccess, paymentFailure } from './payment.action'

import paymentTypes from './payment.types'

export function* creditCardPayment(action) {
  try {
    const response = yield call(paymentFetch, action.payload)
    // console.log(response, 'res')
    if (response.data.status === 'Error') {
      throw new Error(response.data.message)
    }
    yield put(paymentSuccess(response))
  } catch (error) {
    const message = errorHandler(error)
    yield put(paymentFailure(message))
  }
}

const paymentSaga = [takeEvery(paymentTypes.PAYMENT_START, creditCardPayment)]

export default paymentSaga
