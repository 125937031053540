import paymentTypes from './payment.types'
import config from '../../config'
import axios from 'axios'

export const paymentFetch = payload => {
  return axios.post(`${config.baseUrl}/api/pay`, payload)
  // return axios.post(` http://10.90.29.22/api/pay`, payload)
}

export const paymentStart = payload => {
  return {
    type: paymentTypes.PAYMENT_START,
    payload
  }
}

export const paymentSuccess = data => {
  // console.log(data)
  return {
    type: paymentTypes.PAYMENT_SUCCESS,
    payload: data.data
  }
}

export const paymentFailure = error => {
  return {
    type: paymentTypes.PAYMENT_FAILURE,
    error
  }
}
