import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import { ToastContainer, toast } from 'react-toastify'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
// import Payment from "./pages/Payment";

import * as serviceWorker from './serviceWorker'
import App from './pages/App'

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer position={toast.POSITION.TOP_CENTER} />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
