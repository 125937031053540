const dev = process.env.NODE_ENV === 'development';

const prodConfig = {
  baseUrl: 'https://payhub-stg.amanyaman.com',
  // baseUrl: 'https://paymenthub-backend.ati-bv.dev',
};

const devConfig = {
  baseUrl: 'https://payhub-stg.amanyaman.com'
  // baseUrl: 'https://paymenthub-backend.ati-bv.dev',
};

const appConfig = {
  baseUrl: dev ? devConfig.baseUrl : prodConfig.baseUrl,
  baseUrlLive: 'https://opa-ga.atibusinessgroup.com:8001',
  daySessionExpiration: 1,
};

export default appConfig;
