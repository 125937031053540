import { CHECK_CREDIT_CARD_FAILURE, CHECK_CREDIT_CARD_REQUEST, CHECK_CREDIT_CARD_SUCCESS } from './types'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null
}

const creditCardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_CREDIT_CARD_REQUEST:
      return {
        ...state,
        loading: true
      }
    case CHECK_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case CHECK_CREDIT_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default creditCardReducer
