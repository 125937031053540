import React, { useEffect, lazy, Suspense } from 'react';
import 'assets/sassDesktop/ga.scss';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PageLoader from 'components/loader/pageLoader';

const Payment = lazy(() => import('pages/desktop/Payment'));
const PaymentNotFound = lazy(() => import('pages/desktop/PaymentNotFound'));
const PaymentExpired = lazy(() => import('pages/desktop/PaymentExpired'));
const PaymentMobile = lazy(() =>
  import('pages/mobile/paymentMobile/PaymentMobile'),
);
// const PaymentRedirectDoku = lazy(() => import('pages/Redirect/'))
const PaymentRedirectMidtrans = lazy(() => import('pages/Redirect/Midtrans'));
const RedirectPage = lazy(() => import('pages/Redirect/'));
const SuccessPage = lazy(() => import('pages/Redirect/Success'));
const VoucherCreated = lazy(() => import('pages/Redirect/VoucherCreated'));

const App = () => {
  useEffect(() => {
    // console.log(history.location.pathname, match)
    // fetchItineraryStart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    // <BrowserRouter basename="/payment-link">
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route
            path="/payment/:id"
            render={() => {
              // console.log(itinerary.status)
              return isMobile ? <PaymentMobile /> : <Payment />;
            }}
          />
          {/* {isMobile ? (
              <PaymentRoute  path="/base/:id" component={PaymentMobile} />
            ) : (
                <PaymentRoute  path="/base/:id" component={Payment} />
              )} */}
          <Route path="/payment-expired" component={PaymentExpired} />
          {/* <Route path="/payment-redirect/doku" component={PaymentRedirectDoku} /> */}
          <Route
            path="/payment-redirect/midtranss"
            component={PaymentRedirectMidtrans}
          />
          <Route path="/payment-redirect/midtrans" component={RedirectPage} />
          <Route path="/success" component={SuccessPage} />
          <Route path="/voucher-created" component={VoucherCreated} />
          <Route path="/*" component={PaymentNotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;

// export default App;
