import {
    GET_ISO_COUNTRY_REQUEST,
    GET_ISO_COUNTRY_FAILURE,
    GET_ISO_COUNTRY_SUCCESS,
  } from './isoCountry.types';
  
  const INITIAL_STATE = {
    data: null,
    loading: false,
    error: null,
  };
  
  const isoCountryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_ISO_COUNTRY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_ISO_COUNTRY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.data,
        };
      case GET_ISO_COUNTRY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default isoCountryReducer;
  