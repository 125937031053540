import { takeLatest, call, all, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  POST_VOUCHER_SUCCESS,
  POST_VOUCHER_REQUEST,
} from './voucher.types';
import errorHandler from 'utils/errorHandler';

import config from 'config';
import axios from 'axios';

export function* postVoucher({ data }) {
  const options = {
    method: 'POST',
    timeout: 12000,
    baseURL: config.baseUrl + `/api/itinerary/bookingVoucher/v2`,
    data: data,
  };

  try {
    const { data } = yield call(axios, options);
    console.log(data?.status);

    if (data?.status === 'ERROR') throw new Error(data?.message);
    if (data?.status === 'OK') toast.success(data?.message);
    yield put({ type: POST_VOUCHER_SUCCESS, data });
  } catch (error) {
    console.log(error);
   errorHandler(error);
    // yield put(paymentFailure(message))
  }
}

export function* onVoucherPost() {
  yield takeLatest(POST_VOUCHER_REQUEST, postVoucher);
}

export function* voucherWatcher() {
  yield all([call(onVoucherPost)]);
}
