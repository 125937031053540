import { takeLatest, call, all, put } from 'redux-saga/effects'
import { CHECK_CREDIT_CARD_REQUEST, CHECK_CREDIT_CARD_SUCCESS } from './types'

import config from 'config'
import axios from 'axios'

export function* getCheckCreditCard({ data }) {
  console.log(data)
  const options = {
    method: 'GET',
    timeout: 12000,
    baseURL: config.baseUrl + `/api/bin/${data}`
  }

  try {
    const { data } = yield call(axios, options)
    console.log(data)
    yield put({ type: CHECK_CREDIT_CARD_SUCCESS, data })
  } catch (error) {
    const data = error.response.data
    return data
  }
}

export function* onCheckCreditCard() {
  yield takeLatest(CHECK_CREDIT_CARD_REQUEST, getCheckCreditCard)
}

export function* creditCardWatcher() {
  yield all([call(onCheckCreditCard)])
}
