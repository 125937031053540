import {
  POST_CHECK_STATUS_REQUEST,
  POST_CHECK_STATUS_FAILURE,
  POST_CHECK_STATUS_SUCCESS,
} from './checkStatusPayment.types';

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: null,
};

const checkStatusPaymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_CHECK_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_CHECK_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case POST_CHECK_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default checkStatusPaymentReducer;
