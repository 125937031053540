import { all, call } from 'redux-saga/effects';

import itinerarySaga from './itinerary/itinerary.saga';
import paymentSaga from './payment/payment.saga';
import { creditCardWatcher } from './creditCard/saga';
import { voucherWatcher } from './Voucher/voucher.saga';
import { checkStatusPaymentWatcher } from './checkStatusPayment/checkStatusPayment.saga';
import { isoCountryWatcher } from './isoCountry/isoCountry.saga';

export default function* rootSaga() {
  yield all([
    ...itinerarySaga,
    ...paymentSaga,
    call(creditCardWatcher),
    call(voucherWatcher),
    call(checkStatusPaymentWatcher),
    call(isoCountryWatcher)
  ]);
}
