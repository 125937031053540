import { combineReducers } from 'redux';

import itineraryReducer from './itinerary/itinerary.reducer';
import paymentReducer from './payment/payment.reducer';
import methodReducer from './methodPayment/methodPayment.reducer';
import updateVaReducer from './updateVa/update.reducer';
import creditCardReducer from './creditCard/reducer';
import voucherReducer from './Voucher/voucher.reducer';
import checkStatusPaymentReducer from './checkStatusPayment/checkStatusPayment.reducer';
import isoCountryReducer from './isoCountry/isoCountry.reducer';

const rootReducer = combineReducers({
  itinerary: itineraryReducer,
  payment: paymentReducer,
  updateVa:updateVaReducer,
  method: methodReducer,
  creditCard: creditCardReducer,
  voucher: voucherReducer,
  checkStatusPayment: checkStatusPaymentReducer,
  isoCountry: isoCountryReducer
});

export default rootReducer;
